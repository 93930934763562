.checkbox-group .from-group {
    border: 1px solid;
    margin: 11px;
    padding: 15px 0 10px 15px;
    border-radius: 5px;
}

.float_right{
    float: right;
}
.mr-20{
    margin-right: 20px;
}
.pointer{
    cursor: pointer;
}
.steeper-container{
    margin-top: -10px;
    margin-right: 10px;
}

.checkbox-group .from-group:has(input[type=checkbox]:checked) {
    border: 2px solid green;
}

.form-group.gender label {
    border: 1px solid #ccc;
    margin: 4px;
    padding: 15px 50px;
    text-align: center;
    cursor: pointer;
    min-width: 180px;
    border-radius: 5px;
}

.form-group.gender label:has(input[type=radio]:checked) {
    border: 2px solid green;
}


.form-group.gender label > input[type="radio"] {
    display: none;
}

.section-group {
    border-bottom: 1px solid #efefef;
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.section-sub-group {
    padding: 10px 20px;
}