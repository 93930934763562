@charset "utf-8";
.lineDevider{display: block; height: 1px; width: 100%; background-color: #DAE1E9;}
.leftNav{background-color: #FaFaFa;}
.pt55{padding-top: 55px;}
.color7B8{color: #7B879D;}
.leftNav .nav-pills .nav-link{color: #7B879D; padding: 13px 0; padding-left: 0;}
.leftNav .nav-pills .nav-link.active{background-color: transparent; color: #26324A;}
.righttabContent{border-left: 1px solid #DAE1E9;}
.pl90{padding-left: 90px;}
.color263{color: #26324A;}
.tabContent_title{border-bottom: 1px solid #DAE1E9;}
.color0C4{color: #0C444E;}
/*.welcomeimg{display: block; width: 100%; height: 187px; background-color: #0C444E; border-radius: 10px;}*/

.welcomeimg{display: block; width: 100%; border-radius: 10px;}

.goalsumm_outer{display: flex; justify-content: space-between; align-items: flex-start; flex-flow: row nowrap; width: 100%;}
.goalsumm_left, .goalsumm_right{flex: 1;}
.goalsumm_left{background-color: #E2F0E4; border-radius: 10px; padding: 32px;}
.goalsumList{display: flex; justify-content: flex-start; align-items: center; width: 100%; flex-wrap: wrap; padding-left: 20px;}
.goalsumList li{display: flex; width: 100%; position: relative; list-style: disc outside none;
  display: list-item; padding-bottom: 8px;}
.pt70{padding-top: 70px;}
.finnovatetagline{display: flex; align-items: center; justify-content: flex-start; width: 100%;}
.downloadBtn{border-top: 1px solid #DAE1E9;}
.downloadBtn .btn-primary{background-color: #0061C1; border-color: #0061C1;}
.personInfo_outer{display: flex; width: 100%; flex-direction: column; background: linear-gradient(0deg, rgba(236, 255, 233, 0.9), rgba(236, 255, 233, 0.9)), linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #FDFDFD, #FDFDFD), #FAFAFA;
  border-radius: 10px; padding: 15px; }
.infotab{display: inline-flex; height: 22px; align-items: center; justify-content: flex-start; padding: 0 8px;border: 1px solid #D7DCE4; border-radius: 16px; margin-right: 12px;}
.person_info_tbl{border: 1px solid #CFD8E2; border-radius: 10px; }
.person_info_tbl .table{margin-bottom: 0;}
.person_info_tbl .table thead th{border-top: 0; border-bottom: 1px solid #CFD8E2; color: #7E94A7; font-weight: 500;}
.person_info_tbl .table td, .person_info_tbl .table th{border-color: #EBEEF3; color: #182338;
  font-weight: 500;}
.person_info_tbl .table thead th:first-child{width: 300px;}
.person_info_tbl .table td:nth-child(4), .person_info_tbl .table th:nth-child(4){text-align: center;}
.variousList{padding-left: 20px;}
.variousList li{display: flex; width: 100%; flex-wrap: wrap; padding-bottom: 10px; list-style: disc outside none; display: list-item;}
.inflowbox_outer{display: flex; flex-flow: row nowrap; width: 100%; border-radius: 10px; border: 1px solid #CFD8E2;}
.inflowbox_outer_left{flex-basis: 245px; padding: 25px;}
.inflowbox_outer_right{flex: 1; border-left: 1px solid #CFD8E2; padding: 22px 32px;}
.inflowbox_title{display: flex; width: 100%; position: relative; padding-left: 25px;}
.inflowbox_title::before{content: ""; width: 20px; height: 11px; background: #CEDA9D; border-radius: 5px; position: absolute; left: 0; top:3px;}
.inflowdtl{display: grid; grid-template-columns: repeat(3,1fr); grid-gap: 24px;}
.inflowbox_title.orangedot::before{background: #FBAF3C;}
.inflowbox_title.bluedot::before{background: #6AB0F5;}
.inflowbox_title.lightgreendot::before{background: #C3E7CD;}
.inflowbox_title.greendot::before{background: #89DCA0;}
.totalSaving_box{display: flex; flex-flow: row nowrap; width: 100%; align-items: center; padding: 24px; border-radius: 10px; border: 1px solid #CFD8E2; justify-content: space-between;}
.circleBtn_outer{flex-basis: 24px;}
.circleBtn{display: inline-flex; width: 24px; height: 24px; background: #FFFFFF;  border: 1px solid #EBEEF3; align-items: center; justify-content: center; border-radius: 50%; color: #66707C; font-size: 18px; font-weight: 600; cursor: pointer;}
.totalSaving_box.p0{padding: 0;}
.totalSaving_box.borderzero{border: 0;}
.color78B{color: #7B879D;}
.riskbar_outer{display: flex; width: 100%; flex-flow: row nowrap;}
.riskbar_sctn{display: inline-flex; flex-direction: column; flex: 1; text-align: center; cursor: pointer;}
.riskbar_status{height: 24px; background-color: #22AF4A;}
.riskbar_sctn:first-child .riskbar_status{border-radius: 15px 0 0 15px;}
.riskbar_sctn:last-child .riskbar_status{border-radius: 0 15px 15px 0;}
.riskbar_sctn:nth-child(2) .riskbar_status{background-color:#3CC97E;}
.riskbar_sctn:nth-child(3) .riskbar_status{background-color:#FCD64F;}
.riskbar_sctn:nth-child(4) .riskbar_status{background-color:#FD9D4F;}
.riskbar_sctn:nth-child(5) .riskbar_status{background-color:#FF5942;}

.asset_alloc_status{height: 10px;}
.equity_status{
  background-color: #FF5942 !important;
  border-radius: 15px 0 0 15px !important;
}
.debt_status{
  background-color: #3CC97E !important;
  border-radius: 0 15px 15px 0 !important;
}

.apply_roi_right{flex-basis: 35px; display: inline-flex; justify-content: flex-end;}

.applyBtn{
  background: linear-gradient(0deg, #0C444E, #0C444E), #FFFFFF !important;
  border-radius: 11px !important;
  color: #E8FFE4;
    height: 23px !important;
    margin-left: 10px;
}

.suggestBox{display: flex; width: 100%; justify-content: space-between; align-items: center; background: #F7F9FB; border-radius: 12px; padding: 12px 15px;}
.suggestBox_left, .suggestBox_right{flex: 1;}
.suggestBox_right{text-align: right;}
.color0f7{color: #0F7EEB;}
.color505{color: #505A62;}

.loanBox_outer{display: flex; flex-flow: row nowrap; width: 100%; border-radius: 10px; border: 1px solid #CFD8E2; justify-content: space-between; align-items: center;}
.loandtl{display: inline-flex; flex-direction: column; align-items: flex-start; justify-content: flex-start; position: relative; flex: 1; position: relative; padding-left: 30px;}
.loandtl::before{content: ''; height: 100%; width: 1px; background-color: #D7DCE4; position: absolute; left: 0; top: 0;}
.loandtl:first-child:before{display: none;}
.loandtl:first-child{padding-left: 0;}
.color263{color: #26324A;}
.color7b8{color: #7B879D;}
.loaninfo{display: flex; flex-flow: row nowrap; width: 100%; padding: 12px; align-items: center; justify-content: flex-start; position: relative; background-color: rgba(252, 157, 79, 0.2); border-radius: 6px;}
.loaninfo b{padding-left: 4px;}
.mt60{margin-top: 60px;}

.objectivenote{display: flex; flex-direction: column; width: 100%; position: relative; padding: 15px; border-radius: 10px; background-color: #F7F9FB;}

.analysisnote{display: flex; flex-direction: column; width: 100%; position: relative; padding: 15px; border-radius: 10px; background-color: #F9F7EF;}
.analysislist{display: flex; flex-direction: column; width: 100%;padding-left: 20px;}
.analysislist li{color: #38465F; display: flex; width: 100%; margin-top: 8px; display: list-item; list-style-position:outside; list-style-type: disc;}

.lifecoveredbox_outer{display: flex; width: 100%; justify-content: space-between; flex-flow: row nowrap;}
.lifecoveredbox{border: 1px solid #EDEFF2; border-radius: 10px; padding: 20px; flex: 1; margin-right: 8px;}
.lifecoveredbox:last-child{margin-right: 0;}
.coloreb4{color: #EB4751;}

.suggestnote{display: flex; width: 100%; flex-direction: column; position: relative; border-radius: 10px; background-color: #E9F8EB; padding: 32px;}
.suggestlist{display: flex; flex-direction: column; width: 100%;padding-left: 20px;}
.suggestlist li{ width: 100%; margin-top: 8px; display: list-item; list-style-position:outside; list-style-type: disc;}

.goalbox_outer{display: flex; flex-direction: column; width: 100%; position: relative; padding: 15px 25px; border: 1px solid #DAE1E9; border-radius: 10px;} 
.goalbox_title{display: flex; justify-content: space-between; align-items: center; flex-flow: row nowrap; border-bottom: 1px solid #F2F4FB; padding-bottom: 20px;}
.achivetab{display: inline-flex; align-items: center; border: 1px solid #D7DCE4; border-radius: 16px; height: 22px; justify-content: center; padding: 0 10px; cursor: pointer;}
.goalbox_content{display: flex; justify-content: space-between; align-items: flex-start; flex-flow: row nowrap; width: 100%;}
.goalbox_inner{display: inline-flex; flex-direction: column; align-items: flex-start; justify-content: flex-start; position: relative; flex: 1; position: relative; padding-left: 30px;}
.goalbox_inner::before{content: ''; height: 55px; width: 1px; background-color: #D7DCE4; position: absolute; left: 0; top: 0;}
.goalbox_inner:first-child:before{display: none;}
.goalbox_inner:first-child{padding-left: 0;}
.color22a{color: #22AF4A;}
.goalstatus_outer{display: flex; justify-content: center; align-items: center; flex-direction: column; width: 100%; text-align: center;}
.goalstatus_bar{display: flex; width: 100%; align-items: center; flex-flow: row nowrap;}
.goalbar{display: flex; height: 8px; min-width: 175px; background: linear-gradient(90deg, #FC8727 1.43%, #09A635 104.86%); border-radius: 130px; position: relative; justify-content: space-between; align-items: center; padding: 0 8px;}
.goalbar span{display: inline-block; width: 2px; height: 2px; border-radius: 50%; background-color: #fff; margin-top: 0;}
.assumptionbox_outer{display: flex; flex-flow: row nowrap; width: 100%; position: relative; background-color: #EDFAEF; border-radius: 10px;}
.assumptionbox_list{display: flex; width: 100%; flex-direction: column; position: relative;}
.assumptionbox_list li{display: flex; justify-content: space-between; align-items: center; border-bottom: 1px solid #BDD7C0; padding: 15px 25px;}
.assumptionbox_list li:last-child{border-bottom: 0;}

.goalsum_chart_outer{display: flex; width: 100%; position: relative; flex-direction: column;}
.goalsum_chart_box{display: flex; flex-direction: column; width: 100%; position: relative; padding: 15px 25px; border: 1px solid #DAE1E9; border-radius: 10px;}
.goalbox_inner.alignCenter{justify-content: center; align-items: center;}
.goalsum_chart_devider{display: flex; width: 100%; height: 1px; background-color: #F2F4FB;}
.color181{color: #181818;}
.mt70{margin-top: 70px;}

.goalPlan_tbl{display: block; border: 1px solid #EBEEF3; border-radius: 12px; padding: 0 35px;}
.goalPlan_tbl thead th{border-top: 0; color: #6D7584; font-weight: 500; font-size: 12px;}
.goalPlan_tbl .table thead th{border-bottom: 1px solid #EBEEF3;}
.goalPlan_tbl .table thead th:first-child{text-align: center;}
.goalPlan_tbl .table{margin-bottom: 0;}
.goalPlan_tbl .table td, .goalPlan_tbl .table th{border-color: #EBEEF3;}
.goalPlan_tbl .table tbody td, .goalPlan_tbl .table tbody th{font-weight: 500; font-size: 12px; color: #6D7584;}
.goalPlan_tbl .table tbody td:last-child{color: #182338; font-weight: 600;}
.goalPlan_tbl .table tbody th{text-align: center; border-top: 0; color: #0D6CCA;}
.dotstatus{display: inline-flex; align-items: center; position: relative; flex-wrap: wrap;}
.dotstatus::before{content: ''; width: 6px; height: 6px; background-color: #FDC64E; border-radius: 7px; position: absolute; left: -10px; top: 50%; transform: translateY(-50%);}
.dotstatus.darkyellowdot::before{background-color: #C79E5B;}
.dotstatus.greendot::before{background-color: #09A635;}
.dotstatus.bluedot::before{background-color: #0D6CCA;}
.slogantext{display: flex; width: 100%; position: relative; font-size: 36px; color: #0061C1; font-weight: 800;}




.leftNav .nav-link {
  display: inline-flex;
  align-items: center;
}

.welcomeNoteIcon{background-image: url(../img/fpreport_icons/welcomeNote.png); background-repeat: no-repeat; background-size: 100%; display: inline-block; width: 18px; height: 18px;}
.leftNav #Welcomenote-tab.active .welcomeNoteIcon{
  background-image: url(../img/fpreport_icons/welcomeNoteActive.png);
  background-repeat: no-repeat;
}

.disclaimerIcon{background-image: url(../img/fpreport_icons/disclaimer.png); background-repeat: no-repeat; background-size: 100%; display: inline-block; width: 18px; height: 18px;}
.leftNav #Disclaimer-tab.active .disclaimerIcon{
  background-image: url(../img/fpreport_icons/disclaimerActive.png);
  background-repeat: no-repeat;
}

.personalInformationIcon{background-image: url(../img/fpreport_icons/personalInformation.png); background-repeat: no-repeat; background-size: 100%; display: inline-block; width: 18px; height: 18px;}
.leftNav #PersonalInformation-tab.active .personalInformationIcon{
  background-image: url(../img/fpreport_icons/personalInformationActive.png);
  background-repeat: no-repeat;
}

.cashflowAnalysisIcon{background-image: url(../img/fpreport_icons/cashflowAnalysis.png); background-repeat: no-repeat; background-size: 100%; display: inline-block; width: 18px; height: 18px;}
.leftNav #CashflowAnalysis-tab.active .cashflowAnalysisIcon{
  background-image: url(../img/fpreport_icons/cashflowAnalysisActive.png);
  background-repeat: no-repeat;
}

.networthAnalysisIcon{background-image: url(../img/fpreport_icons/networthAnalysis.png); background-repeat: no-repeat; background-size: 100%; display: inline-block; width: 20px; height: 20px;}
.leftNav #NetworthAnalysis-tab.active .networthAnalysisIcon{
  background-image: url(../img/fpreport_icons/networthAnalysisActive.png);
  background-repeat: no-repeat;
}

.assetsIcon{background-image: url(../img/fpreport_icons/assets.png); background-repeat: no-repeat; background-size: 100%; display: inline-block; width: 18px; height: 18px;}
.leftNav #Whatyouown-tab.active .assetsIcon{
  background-image: url(../img/fpreport_icons/assetsActive.png);
  background-repeat: no-repeat;
}

.emergencyFundIcon{background-image: url(../img/fpreport_icons/emergencyFund.png); background-repeat: no-repeat; background-size: 100%; display: inline-block; width: 18px; height: 18px;}
.leftNav #EmergencyFund-tab.active .emergencyFundIcon{
  background-image: url(../img/fpreport_icons/emergencyFundActive.png);
  background-repeat: no-repeat;
}

.loanManagementIcon{background-image: url(../img/fpreport_icons/loanManagement.png); background-repeat: no-repeat; background-size: 100%; display: inline-block; width: 18px; height: 18px;}
.leftNav #LoanManagement-tab.active .loanManagementIcon{
  background-image: url(../img/fpreport_icons/loanManagementActive.png);
  background-repeat: no-repeat;
}

.lifeAnalysisIcon{background-image: url(../img/fpreport_icons/lifeAnalysis.png); background-repeat: no-repeat; background-size: 100%; display: inline-block; width: 20px; height: 20px;}
.leftNav #LifeInsuranceAnalysis-tab.active .lifeAnalysisIcon{
  background-image: url(../img/fpreport_icons/lifeAnalysisActive.png);
  background-repeat: no-repeat;
}

.healthAnalysisIcon{background-image: url(../img/fpreport_icons/healthAnalysis.png); background-repeat: no-repeat; background-size: 100%; display: inline-block; width: 18px; height: 18px;}
.leftNav #HealthAnalysis-tab.active .healthAnalysisIcon{
  background-image: url(../img/fpreport_icons/healthAnalysisActive.png);
  background-repeat: no-repeat;
}

.goalSummaryIcon{background-image: url(../img/fpreport_icons/goalSummary.png); background-repeat: no-repeat; background-size: 100%; display: inline-block; width: 18px; height: 18px;}
.leftNav #GoalSummary-tab.active .goalSummaryIcon{
  background-image: url(../img/fpreport_icons/goalSummaryActive.png);
  background-repeat: no-repeat;
}

@media only screen and (max-width: 767px) {
  
  


}

@media only screen and (min-width: 768px) and (max-width: 959px){


}


/* iPad in portrait & landscape */

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  { 
 

}

/* iPad in landscape */

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) { 

}


/* iPad in portrait */

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) { 
    .dashboard_right{display: none;}
    .dashboard_left{flex-basis: 100%;}
 }