.table-compare-plans{
    width: 100%;
    border-collapse: collapse;
}

table.table-compare-plans tr td{
    text-align: center;
}
table.table-compare-plans tr td:first-child {
    text-align: left;
}

table.table-compare-plans img.logo {
    max-height: 100px;
}

table.table-compare-plans .sticky-row { 
    position: sticky !important;
    top: 0 !important;
    z-index: 99999 !important;
}

table.table-compare-plans tbody {
    height: 300px;
    overflow-y: scroll;
}
 