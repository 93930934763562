select {
    appearance: auto;
}
.right{
    text-align: right;
}
.text-right{
    text-align: right;
}
.full-width{
    width: 100%;
}
.height10 {
    height: 10px;
}
.height20 {
    height: 20px;
}
.height30 {
    height: 30px;
}
.height40 {
    height: 40px;
}
.height50 {
    height: 50px;
}

span.make-circle {
    border: 1px solid;
    border-radius: 53%;
    padding: 0px 2px;
}
 
.position-absolute{
    position: absolute;   
}
.right-0{
    right: 0px;
}
.right-5{
    right: 5px;
}
.right-10{
    right: 10px;
}
.right-15{
    right: 15px;
}
.right-20{
    right: 20px;
}
.border-buttom{
    border-bottom:1px solid #ccc;
}
.premium-info-dropdown{
    color: var(--text-text-800, #26324A);
    font-feature-settings: 'case' on; 
    font-family: Inter;
    /*font-size: 14px;*/
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.14px;
    border: none;
    background: white;
    text-align: right;
    padding-right: 5px;
}

.beadcrumbs{
    padding-top:10px;
}

.beadcrumbs a, .beadcrumbs span{
    color: #7B879D; 
    color: var(--text-text-500, #626E84);
    font-feature-settings: 'case' on; 
    /* Regular / Regular 13pt */
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px; /* 115.385% */
    margin-right: 10px;
    margin-left: 10px; 
}

.beadcrumbs a:first-child{
    margin-left: 0px; 
}
.beadcrumbs span{
    color: var(--text-text-700, #38465F);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px; /* 115.385% */
}
.max-width100{
    max-width: 100px;
}
 
.life-insurance-details .card .tags{
    color: var(--text-text-600, #485670);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 10px */
    letter-spacing: 2px; 
   
    border-radius: 3px;
    background: var(--stone-gray-stone-gray-100, #EBEEF3);
    padding: 3px 4px;
}

.border-hr{
    border: 1px solid #EBEEF3;;
}
.heading-1{
    color: var(--text-text-800, #26324A);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 20px */
    letter-spacing: -0.18px;
}

.heading-2{
    color: var(--text-text-800, #26324A);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 18px */
    letter-spacing: -0.144px;
}

.heading-3{
    color: #26324A; 
    font-feature-settings: 'dlig' on, 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
    letter-spacing: 0.028px;
   
}
.text-type-1{
    color: var(--text-text-500, #626E84);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 153.846% */
    letter-spacing: -0.13px; 
}
.text-type-1-1{
    color: var(--text-text-500, #626E84);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 153.846% */
    letter-spacing: -0.07px;
}

.text-type-2{
    color: var(--text-text-800, #26324A);
    font-feature-settings: 'case' on;
    /* line/medium/14pt */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;  
    letter-spacing: -0.14px;
}

.text-type-3{
    color: var(--text-text-500, #626E84);
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;  
    letter-spacing: -0.055px;
}

.text-type-4{
    color: var(--text-text-500, #626E84);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;  
    letter-spacing: -0.07px;
}

.text-type-5{
    color: var(--text-text-500, #626E84);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;  
}

.text-type-3_1{
    color: var(--text-text-500, #626E84);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
    letter-spacing: -0.07px;
}

.life-insurance-details .card {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 0.5px 3px 0px rgba(0, 0, 0, 0.07), 0px 0px 1px 0px rgba(0, 0, 0, 0.12);
}

.btn-display-amount{
    display: inline-flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #ACB2C1;
    background: #FFF;
    position: absolute;
    right: 12px;
}

.btn-display-amount-2{ 
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #ACB2C1;
    background: #FFF;    
    display: flex;   

}


.extra-benefits .item {
    padding: 10px 20px;
}

.extra-benefits .heading-3{
    margin-bottom: 7px;
}
.color-primary{
    color: var(--primary-blue-primary-blue-600, #0D6CCA);
    font-feature-settings: 'dlig' on, 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
    letter-spacing: 0.028px;
}
.total-to-pay-area{
    border-radius: 8px;
    background: #F1EEFB;

    color: #182338;
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    padding: 15px;
}

.text-tax-saving{
    color: var(--green-green-600, #2DB266);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 116.667% */
    letter-spacing: -0.06px;
}

.about-the-insurer .rc-slider {
    padding: 0 !important;
    margin-bottom: 10px;
}
.about-the-insurer .slider-section{
    min-height: 50px;
}
.continue-to-buy-btn{
    background-color: #0D6CCA;
    width: 100%;
    height: 38px;
    fill: var(--primary-blue-primary-blue-600, #0D6CCA);
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0.5px 3px rgba(0, 0, 0, 0.08));
    border-radius: 8px;
    border: none;
}

.finn-primary-btn{
    background-color: #0D6CCA;
    width: 150px;
    height: 38px;
    fill: var(--primary-blue-primary-blue-600, #0D6CCA);
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0.5px 3px rgba(0, 0, 0, 0.08));
    border-radius: 8px;
    border: none;
}

/**accordion css start */
 
.rc-accordion-toggle {
   display: flex;
   align-items: flex-start;
   cursor: pointer;
   justify-content: space-between;
   /* background-color: #ebedf0; */
   transition: 0.3s;
}
.rc-accordion-toggle.active{
  /*  background-color: #5a8dee; */
}
.rc-accordion-toggle.active .rc-accordion-icon{
   transform: rotate(180deg);
 /*   color: #fff; */
}
.rc-accordion-card{
   border: 1px solid #ddd;
   border-radius: 5px;
   margin-bottom: 10px;
   overflow: hidden;
}
.rc-accordion-card:last-child{
   margin-bottom: 0;
}
.rc-accordion-title{
    color: #26324A; 
    font-feature-settings: 'dlig' on, 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
    letter-spacing: 0.028px;
}
.rc-accordion-toggle.active .rc-accordion-title{
  /*  color: #fff; */
}
.rc-accordion-icon{
   position: relative;
   top: 2px;
   color: #475F7B;
   transition: 0.35s;
   font-size: 12px;
}
.rc-accordion-body{
   flex: 1 1 auto;
   min-height: 1px;
   padding: 15px;
   padding-top: 0;

   
}
.rc-accordion-body p{ 
   color: var(--text-text-500, #626E84);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 153.846% */
    letter-spacing: -0.13px;
    padding-left: 32px;
}
.rc-collapse{
   position: relative;
   height: 0;
   overflow: hidden;
   transition: height 0.35s ease;
}
.rc-collapse.show{
   height: auto;
}

.rc-accordion-title span.sr_no { 
    background-color: #cccccc61;
    border-radius: 50%;
    padding: 3px 7px;  
    color: var(--text-text-300, #9BA7BC);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off; 
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;    
}

.life-insurance-details .rc-accordion-card {
    border: none;
}

.active .rc-accordion-title span.sr_no {
    background: #0d6cca;
    color: white;
}
/* end of accordion css*/
 
.download-btn {
    background: #cccccc2b;
    color: #6c757d !important;
    border: 1px solid #ccc;
    width: 180px;
}
 


/* background: */
.about-the-insurer progress::-webkit-progress-bar {background-color: #0d6cca; width: 100%; height: 6px; border-radius: 6px;}
.about-the-insurer progress {background-color: #0d6cca; height: 6px; border-radius: 6px;}

/* value: */
.about-the-insurer progress::-webkit-progress-value {background-color: #0d6cca !important; border-radius: 4px;}
.about-the-insurer progress::-moz-progress-bar {background-color: #0d6cca !important; border-radius: 6px;}
.about-the-insurer progress {color: #0d6cca; border-radius: 6px; width: 100%;}

/* background: */
.about-the-insurer progress::-webkit-progress-bar {background-color: #ccc; width: 100%; height: 6px; border-radius: 6px;}
 .about-the-insurer progress {background-color: #ccc; height: 6px; border-radius: 6px;}

/* value: */
 .about-the-insurer progress::-webkit-progress-value {background-color: #0d6cca !important; border-radius: 4px;}
 .about-the-insurer progress::-moz-progress-bar {background-color: #0d6cca !important; border-radius: 6px;}
 .about-the-insurer progress {color: #0d6cca; border-radius: 6px; width: 100%;}

 .avatar-display img {
    margin-left: -10px;
}

 @media only screen and (max-width: 767px){
    .life-lnsurance-logo-section {
        margin: 0;
        padding: 0;
    }
    .life-lnsurance-logo-section img.logo{
        width: 100%;
        min-width: 80px;
        height: 50px;
    }
    .beadcrumbs {
        padding: 10px;
    }
    .col-md-12 {
        padding: 0;
    }
    .mt20 {
        margin-top: 20px !important;
    }
    .download-policy-document img {
        width: 100px;
        margin-top: 12px;
    }
    .total-to-pay-area span.ml50{
       position: absolute;
       right: 15px;
    }
    .mobile-top-margin-20 {
        margin-top: 20px;
    }

    .mobile-item-list li img{
        position: absolute;
        left: 0;
    }
    .mobile-item-list li{
        padding-left: 25px;
        line-height: 1.6;
    }

    .mobile .rc-accordion-title {
        line-height: 1.6;
    }
   .mobile .rc-accordion-title span {
        position: absolute;
        left: 20px;
        width: 25px;
    }

    .mobile .rc-accordion-toggle {
        padding-left: 30px !important;
    }
    .rc-accordion-body p{
        padding-left: 15px;
    }

    .mobile-pl0{
        padding-left: 0px;
    }
    .mobile-pr0{
        padding-right: 0px;
    }
    .mobile-pt0{
        padding-top: 0px;
    }
    .mobile-pb0{
        padding-bottom: 0px;
    }
    .mobile-pl0-i{
        padding-left: 0px !important;
    }
    .mobile-pr0-i{
        padding-right: 0px !important;
    }
    .mobile-pt0-i{
        padding-top: 0px !important;
    }
    .mobile-pb0-i{
        padding-bottom: 0px !important;
    }

    .extra-benefits .item {
        padding: 10px 0px;
    }
    .mobile2 .rc-accordion-toggle{
        padding-left: 50px !important;
    }

 }


 .radio-buttons label {
    padding: 10px 30px;
    border: 1px solid #efefef;
    margin-right: 20px;
    border-radius: 5px;
}

.radio-buttons label input[type="radio"] {
    transform: scale(2);
    margin-right: 10px;
}

.left-input-group {
    padding-right: 15px !important;
}

.right-input-group {
    padding-left: 15px !important;
}

span.mobile-prifix-code{
    background: #eef1f5cc;
    padding: 10px 7px;
    position: absolute;
}
 
table.text-type-4.table-product-details td {
    padding: 5px 0px;
    line-height: 14px;
}   

.proposer-details-form-section input[type=text] , .proposer-details-form-section select {
    border-radius: 8px;
    border: 1px solid #ACB2C1;
    background: #FFF; 
    color: #6D7584;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.056px; 
    height: 45px; 
}

.proposer-details-form-section select {
    min-height: 45px;
}


.camera-box{
    border: 1px solid #ccc;
    width: 65px ;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    box-shadow: 0px 0.5px 3px 0px rgba(0, 0, 0, 0.07), 0px 0px 1px 0px rgba(0, 0, 0, 0.12);
}

.padding_5-7{
    padding: 5px 7px !important;
}

.card-bg-gray{
    border-radius: 12px;
    border: 1px solid var(--stone-gray-stone-gray-100, #EBEEF3);
    background: var(--stone-gray-stone-gray-50, #F7F9FB);
}

.mini-btn-1 {
    color: var(--text-text-500, #626E84);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;    
    
    background: #efefef;
    padding: 10px 5px;
    margin: 3px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 11px;
}


ul.ul-list li {
   line-height: 1.6;
}