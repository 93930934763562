.logincard{display: block; width: 100%;}
.logincard_title{display: block; border-bottom: 1px solid #EBEEF3;}
.googlebtn{display: flex; align-items: center; justify-content: center; height: 48px; border: 1px solid #DAE1E9; box-shadow: 0px 0px 1px rgba(155, 155, 155, 0.12); border-radius: 8px;}
.gmailicon{background-image: url(../img/gmailicon.png); width: 22px; height: 22px; display: inline-block; background-repeat: no-repeat; background-size: 100%; opacity: 0.1;}
.googleOpacity{
    opacity: 0.2;
}
.orline{display: flex; align-items: center; justify-content: center; width: 100%; position: relative; height: 1px; background-color: #EBEEF3;}
.orline span{padding: 5px 10px; background-color: #fff;}
.mobilefield{display: flex; height: 44px; align-items: center; justify-content: flex-start; position: relative; border: 1px solid #ACB2C1; border-radius: 8px;}
.mobilefield input{display: block; width: 100%; border: 0; padding: 0 15px;}
.mobilefield input::placeholder{color: #6D7584; font-weight: 500; font-size: 14px;}
.continuebtn{background: #0D6CCA; height: 40px; border-radius: 8px;}
.continuebtn.btn-primary.disabled, .continuebtn.btn-primary:disabled{background-color: #D7DCE4; border-color: #D7DCE4; color: #fff;}
.signuptext{display: flex; align-items: center; justify-content: center; width: 100%; text-align: center;}
.logintnctext{display: block; width: 100%; text-align: center;}
.logincard_title .btn{position: absolute; right: 15px; top: 15px; padding: 2px 10px 2px 7px;}
.blueleftarrow{background-image: url(../img/blueleftarrow.png); width: 4px; height: 8px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}
.otptext{display: block; width: 100%; text-align: center;}
.otpfield{display: grid; grid-template-columns: repeat(6, 1fr); width: 100%; grid-gap: 15px;}
.otpfield input{width: 100%; height: 44px; text-align: center; border: 1px solid #ACB2C1; border-radius: 4px; background-color: transparent;}
.coloreb4{color: #EB4751;}
.eyeicon{background-image: url(../img/eyeicon.png); width: 16px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}
.forgotpass_card{display: flex; justify-content: space-between; width: 100%;}
.rememberme{display: inline-flex;}
.remembermecheckbox{display: inline-flex; border: 1px solid #B7C0CF; border-radius: 3px;}
.mobilefield.errorinpt{border-color: #EB4751;}


/* Smallpopup */
.deleteIcon{background-image: url(../img/deleteIcon.png); width: 65px; height: 65px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}

.errorIcon{background-image: url(../img/errorIcon.png); width: 65px; height: 65px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}

.successIcon{background-image: url(../img/successIcon.png); width: 65px; height: 65px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}

.addIcon{background-image: url(../img/addIcon.png); width: 65px; height: 65px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}

.color1e3{color: #1E334B;}
.smallbtn{display: flex; justify-content: space-between; align-items: center; width: 100%;}
.smallbtn .btn{display: inline-flex; width: calc(50% - 7px); text-align: center; justify-content: center;}
.modal-sm.deletepopup{max-width: 350px;}
.forgetpass{display: flex; align-items: flex-end; width: 100%; justify-content: flex-end;}
.verifypasswordicon{background-image: url(../img/verifypasswordicon.png); width: 65px; height: 65px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}

.show-hide{
    top: 22px !important;
}

/* Smallpopup */



@media only screen and (max-width: 767px) {
    .formWpr{margin: 0;}
    .logincard_title{padding: 20px;}
    .logincard_title + .p35{padding: 25px 20px;}

}