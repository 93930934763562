@charset "utf-8";
/* form css */
.formWpr{ border: 1px solid #EBEEF3; border-radius: 12px; min-height: 550px;}
.formouter{background-color: #fff; padding: 28px 0 0 0; border-radius: 12px 0 0 12px;}
.qouteouter{background-color: #EBEEF3; padding: 28px 0 0 0; border-radius: 0 12px 12px 0;position: relative;}
.form_swipe_tab{display: flex; width: 100%; align-items: center; position: relative; padding: 0 35px;}
.form_swipe_tab li{border:1px solid #C6CFD7; width: 28px; height: 28px; color: #0C444E; border-radius: 50%; cursor: pointer; margin-right: 10px; display: inline-flex; align-items: center; justify-content: center;} 
.form_swipe_tab li.act{background-color: #0C444E; color: #fff; border: 0;}
.formtitle{border-bottom: 1px solid #EBEEF3; padding: 20px 35px 22px;}
li.backto{background-color: #EBEEF3; width: auto; border-radius: 45px; padding: 0 10px; border: 0; color: #0C444E;}
.qoutetext{padding-top: 28px;}
.qoutesign{ font-size: 52px; line-height: 0; padding: 0; margin: 0; color: #0D6CCA;}
.qouteimg{/*background-image: url(../img/home_form_img.png);*/ background-repeat: no-repeat; /*width: 343px; height: 338px;*/ position: absolute; right: 0; bottom: 0;}
.bluebtn .whitearrow{margin-left: 5px;}
.quantity_wpr{display: inline-flex; align-items: center;}
.quantity_wpr button{background-color: #EBEEF3; width: 40px; height: 40px; display: inline-flex; justify-content: center; align-items: center; flex-flow: row nowrap; border: 0; cursor: pointer; color: #182338; border-radius: 50%; font-size: 22px; outline: none;}
.quantity_wpr input{border: 1px solid #ACB2C1; width: 50px; height: 44px; border-radius: 8px; display: inline-flex; justify-content: center; align-items: center; text-align: center;}

/* steps css */
.steps_tag{display: flex; width: 100%; flex-direction: row; flex-wrap: wrap;}
.steps_tag li{display: inline-flex;}
.steps_tag li label{margin: 0;}
.steps_tag li label .rdo_text_box{display: flex; justify-content: center; align-items: center; flex-flow: row nowrap; background-color: #EBEEF3; padding: 9px 15px; border-radius: 32px; color: #182338; margin-bottom: 12px; margin-right: 12px; cursor: pointer;}
.steps_tag li label input{display: none;}
.steps_tag li label input[type="radio"]:checked + .rdo_text_box{color: #fff; background-color: #2DB266;}
.steps_tag li label input[type="checkbox"]:checked + .rdo_text_box{color: #fff; background-color: #2DB266;}
.calendarIcon{background-image: url(../img/svg/calendar.svg); background-repeat: no-repeat; width: 15px; height: 15px; position: absolute; right: 15px; bottom: 13px;}
.tick{background-image: url(../img/svg/tick.svg); background-repeat: no-repeat; width: 12px; height: 8px; display: inline-block;}
.custome_form.searchinput .form-row .form-group .form-control{border-radius: 32px; padding-left: 30px;}
/* steps css */


/* custome form css */
.custome_form .form-row .form-group.pr20, .custome_form .form-row .pr20{padding-right: 20px;}
.custome_form .form-row .form-group.pl20, .custome_form .form-row .pl20{padding-left: 20px;}
.custome_form .form-row .form-group.pr20, .custome_form .form-row .pr30{padding-right: 30px;}
.custome_form .form-row .form-group.pl20, .custome_form .form-row .pl30{padding-left: 30px;}
.formdevider{display: block; height: 1px; width: 100%; background-color: #EBEEF3;}

.custome_form .form-row .form-group .form-control, .custome_form .form-row .input-group .form-control{border-color: #ACB2C1; }
.custome_form .form-row .input-group .form-control{padding-left: 25px;}
.custome_form .form-row .input-group .form-control:focus{background-color: transparent;}
.custome_form .form-row .form-group .input-group-text{background-color: #fff; border-left-color: #ACB2C1; border-top-color: #ACB2C1; border-bottom-color: #ACB2C1;}
.custome_form .form-row .form-group .input-group-prepend + .form-control{border-left-color: #fff; padding-left: 0;}
.custome_form .form-row .form-group .form-control::placeholder{font-size: 14px; color: #6D7584;}
.custome_form .form-row .form-group label{color: #212121;}
.custome_form .form-row .input-group, .custome_form .form-row .form-group{position: relative;}
.custome_form .form-row .input-group .btn-outline-secondary{background: linear-gradient(0deg, #F5F5F5 0%, #FAFCFC 100%); border: 1px solid #ACB2C1; color: #212121; font-size: 14px; font-weight: 500; border-top-right-radius: .25rem; border-bottom-right-radius: .25rem;}
.rupeesign{position: absolute; left: 15px; top: 7px; z-index: 1;}

/*.custome_form .form-row .input-group .custom-select{border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem; border: 1px solid #ACB2C1; background: linear-gradient(0deg, #F5F5F5 0%, #FAFCFC 100%); max-width: 30%; height: auto;}*/

    .custome_form .form-row .input-group .custom-select{
        border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border: 1px solid #ACB2C1;
    background: linear-gradient(0deg, #F5F5F5 0%, #FAFCFC 100%);
    max-width: 30%;
    height: auto;
    font-size: 14px;
    color: #212121;
    letter-spacing: -0.004em;
    font-weight: 500;
    font-style: normal;
    font-family: 'Inter';
    display: flex;
    }

.custome_form .form-row .input-group.selectarrow::after {
          content: ""; display: inline-block; width: 0; height: 0; border-top: 0.3em solid; border-right: 0.3em solid transparent; border-bottom: 0;  border-left: 0.3em solid transparent; position: absolute;
          right: 10px; top: 50%; transform: translateY(-50%); z-index: 4;}
.custome_form .form-row .input-group.select50 .custom-select{max-width: 50%; padding: 0 22px 0 8px;
    font-size: 14px; font-weight: 500;}
.rangeslider_wpr{display: flex; justify-content: space-between; flex-flow: row nowrap; width: 100%;}
.rangeslider_left{flex: 1; background-color: #F2F4F8; border-radius: 12px; display: inline-flex; justify-content: center; align-items: center; padding: 0 12px;}
.rangeslider_right{flex-basis: 50px;}
.rangeslider_right input{border: 1px solid #ACB2C1;  width: 50px; height: 44px; border-radius: 8px;
    display: inline-flex;justify-content: center;align-items: center; text-align: center;}
.custome_form .form-row .rangeslider_wpr .rangeslider_left .form-group{margin: 0;}
.custome_form .form-row .rangeslider_wpr .rangeslider_left .form-group .form-control-file, .custome_form .form-row .rangeslider_wpr .rangeslider_left .form-group .form-control-range{height: 4px;}
.entergoalname{display: inline-flex; max-width: 140px;}
.entergoalname .custome_form.searchinput .form-row .form-group .form-control{background-color: rgba(235, 238, 243, 0.7); border: 0; padding-left: 20px;}
.selectgoal{display: inline-flex; flex: 1; justify-content: flex-end;  align-items: center;}
.entergoalname_wpr{flex-basis: 140px;}
.selectgoal .input-group{flex-basis: 140px;}
.selectgoal .custom-select{border-radius: 32px; border-color: #ACB2C1; background: transparent;}
.selectgoal .input-group.selectarrow::after{content: ""; display: inline-block;
    width: 0; height: 0; border-top: 0.3em solid; border-right: 0.3em solid transparent;
    border-bottom: 0; border-left: 0.3em solid transparent; position: absolute; right: 15px;
    top: 50%; transform: translateY(-50%); z-index: 4;}
.form_swipe_tab.goldtext li.backto{color: #C79C5A;}
.form_swipe_tab.goldtext li.act {background-color: #C79C5A;}
.addincome_wpr{display: flex; justify-content: space-between; align-items: center; flex-flow: row nowrap; width: 100%;}
.addincome_btn{display: inline-flex; justify-content: center; align-items: center; flex-flow: row nowrap; padding: 8px 12px; background: linear-gradient(0deg, #EBEEF3, #EBEEF3), #FFFFFF;
    border-radius: 8px; cursor: pointer; border: 0; outline: none;}
.addincome_btn:focus{border: 0; outline: 0;}
.entergoalname_wpr.widthauto, .entergoalname_wpr.widthauto .entergoalname{flex-basis: initial; max-width: initial;}
.selectgoal.propertysearch{width: 100%;}
.selectgoal.propertysearch .input-group{flex-basis: initial;}
.custome_form .selectgoal.propertysearch .input-group .custom-select{max-width: 100%; border-radius: 4px; background: #fff;}
.input-group.inputround .form-control{border-radius: .25rem;}
.linkproperty{display: inline-flex; align-items: center; justify-content: center; flex-flow: row nowrap;}
.linkproperty .form-control{max-width: 120px; border-radius: 20px; background: #FAFAFA; border: 1px solid #ACB2C1;}
.investmentTabs{display: flex; flex-flow: row nowrap; width: 100%;}
.investmentTabs ul{display: flex; flex-flow: row wrap; width: 100%; padding-left: 35px;}
.investmentTabs ul li{margin-right: 8px; margin-bottom: 8px;pointer-events: none;}
.investmentTabs ul li label{margin: 0; padding: 0}
.investmentTabs ul li label input{display: none;}
.investmentTabs ul li label input[type=radio]:checked + .investmentTabs_text{background-color: #0A69C7; color: #fff;}
.investmentTabs ul li label .investmentTabs_text{display: inline-flex; justify-content: center; align-items: center; height: 22px; border-radius: 45px; background-color: #EBEEF3; font-size: 12px; font-weight: 500; color: #353F51; padding: 0 8px;}

/* custome form css */

/* Save button */
.saveBtn{position: fixed; left: inherit; bottom: 0; right: 0; background-color: #fff; padding: 15px; border-top: 1px solid #f2f2f2; z-index: 4; width: 399px;}
.saveBtn .btn{width: 100%; font-size: 14px; background-color: #0061C1; border-color: #0061C1;}

.saveBtn.backsaveBtn .btn{width: calc(50% - 7px); }
.saveBtn.backsaveBtn .btn:first-child{margin-right: 14px; background-color: #EBEEF3; border-color: #EBEEF3; color: #26324A;}

/* Save button */


/* Save button */
.updateStatusBtn{left: inherit; bottom: 0; right: 0; background-color: #fff;}
.updateStatusBtn .btn{width: 100%; font-size: 14px; background-color: #0061C1; border-color: #0061C1;}

.updateStatusBtn.backsaveBtn .btn{width: calc(50% - 7px); height: 48px;}
.updateStatusBtn.backsaveBtn .btn:nth-child(2){margin-left: 14px; background-color: #EBEEF3; border-color: #EBEEF3; color: #26324A;}

/* Save button */



@media only screen and (max-width: 767px) {
.innercontainer{width: 100%;}
 .row {margin: 0;}
 .formWpr{border: 0; border-radius: 0;}
 .hdrCol{padding-left: 20px; padding-right: 20px;}
 .form_title{padding-left: 20px; padding-right: 20px;}
 .form_title > .mt30{margin-top: 20px;}
 .formWpr{margin-top: 20px;}
 .custome_form .form-row .form-group.pr20, .custome_form .form-row .pr30,
 .custome_form .form-row .form-group.pl20, .custome_form .form-row .pl20,
 .custome_form .form-row .form-group.pr20, .custome_form .form-row .pr20,
 .custome_form .form-row .form-group.pl20, .custome_form .form-row .pl30{padding: 0;}
.form_swipe_tab, .formtitle, .form_prnt.pl35, .form_prnt.pr35, .mob_left_right {padding-left:20px; padding-right:20px;}
.addincome_btn{white-space: nowrap; margin-left: 10px;}
.mob_mb20{margin-bottom: 20px;}
.custome_form .form-row .input-group.select50 .custom-select{max-width: 30%;}

.custome_form .form-row .rangeslider_wpr .rangeslider_left .form-group .form-control-file, .custome_form .form-row .rangeslider_wpr .rangeslider_left .form-group .form-control-range{width: calc(100vw - 130px);}
.entergoalname_wpr, .entergoalname, .entergoalname_wpr .form-group, .entergoalname_wpr .form-row, .entergoalname_wpr .searchinput, .entergoalname_wpr .form-group .form-control{width: 100%; max-width: 100%;}

.formouter{padding-top: 20px;}



}

@media only screen and (min-width: 768px) and (max-width: 959px){



}

/* iPad in portrait & landscape */

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  { 
 .innercontainer{width: 100%;}
 .row {margin-left: 0; margin-right: 0;}
 .hdrCol{padding-left: 20px; padding-right: 20px;}
 .form_title{padding-left: 20px; padding-right: 20px;}
 .linkproperty {margin-top: 20px;}
}

/* iPad in landscape */

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) { 

}


/* iPad in portrait */

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) { 
    .qouteouter {display: none !important;}

 }




