
.mini-btn-1 {
    color: var(--text-text-500, #182338);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;    
    
    background: #EBEEF3;
    padding: 10px 5px;
    margin: 3px;
    border-radius: 6px;
    cursor: pointer; 
}


.plus-minus-wpr button {
    background-color: #EBEEF3;
    width: 20%;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
    border: 0;
    cursor: pointer;
    color: #182338;
    border-radius: 5px;
    font-size: 13px;
    outline: none;
    padding: 5px 10px;
}


.plus-minus-wpr input {
    border: 1px solid #ACB2C1;
    width: 50%;
    height: 44px;
    border-radius: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.plus-minus-wpr .rupee-icon{
    position: absolute; 
    font-size: 25px;
    margin-top: 5px;
    margin-left: 25px;
}
.tragetvalue{
    height: 35px;
}
@media only screen and (max-width: 767px){
    .plus-minus-wpr input{
        width: 35%;
    }
    .plus-minus-wpr button {
        width: 25%;
    }
}