.card-border{
    /* border:  1px solid #1159a1; */
    border:  1px solid #e9ecef; 
    background: #e9ecef59;
   /*  box-shadow: 0px 2px 15px 0px; */
}
.custome_form .form-row .input-group .form-control {
    padding-left: 10px;
}
 

.alert-info-new {
    color: #0c5460;
    background-color: #e6f9e6;
    border-color: #e6f9e6;
} 

.c-blue{  color:  #007bff; }
.c-indigo{  color:  #6610f2; }
.c-purple{  color:  #6f42c1; }
.c-pink{  color:  #e83e8c; }
.c-red{  color:  #dc3545; }
.c-orange{  color:  #fd7e14; }
.c-yellow{  color:  #ffc107; }
.c-green{  color:  #28a745; }
.c-teal{  color:  #20c997; }
.c-cyan{  color:  #17a2b8; }
.c-white{  color:  #fff; }
.c-gray{  color:  #6c757d; }
.c-gray-dark{  color:  #343a40; }
.c-primary{  color:  rgb(13, 108, 202); }
.c-secondary{  color:  #6c757d; }
.c-success{  color:  #28a745; }
.c-info{  color:  #17a2b8; }
.c-warning{  color:  #ffc107; }
.c-danger{  color:  #dc3545; }
.c-light{  color:  #f8f9fa; }
.c-dark{  color:  #343a40; }

.bg-blue{  background:  #007bff; }
.bg-indigo{  background:  #6610f2; }
.bg-purple{  background:  #6f42c1; }
.bg-pink{  background:  #e83e8c; }
.bg-red{  background:  #dc3545; }
.bg-orange{  background:  #fd7e14; }
.bg-yellow{  background:  #ffc107; }
.bg-green{  background:  #28a745; }
.bg-teal{  background:  #20c997; }
.bg-cyan{  background:  #17a2b8; }
.bg-white{  background:  #fff; }
.bg-gray{  background:  #6c757d; }
.bg-gray-dark{  background:  #343a40; }
.bg-primary{  background:  #007bff; }
.bg-secondary{  background:  #6c757d; }
.bg-success{  background:  #28a745; }
.bg-info{  background:  #17a2b8; }
.bg-warning{  background:  #ffc107; }
.bg-danger{  background:  #dc3545; }
.bg-light{  background:  #f8f9fa; }
.bg-dark{  background:  #343a40; }

.valuecount_primary{
    color: #007bff;
}
.valuecount_primary::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: #007bff;
    border-radius: 3px;
}


.valuecount_red{
    color: #dc3545;
}
.valuecount_red::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: #dc3545;
    border-radius: 3px;
}

.valuecount_purple{
    color: #6f42c1;
}
.valuecount_purple::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: #6f42c1;
    border-radius: 3px;
}

.valuecount_pink{
    color: #dc3545;
}
.valuecount_pink::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: #dc3545;
    border-radius: 3px;
}

.valuecount_yellow{
    color: #ffc107;
}
.valuecount_yellow::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: #ffc107;
    border-radius: 3px;
}

.dashboard_right{
    display: block !important;
}


.graphvalue_count_{
    border-radius: 20px;
    color:rgb(13, 108, 202);
    background: #FCF8EB;
    padding: 3px 20px;
}

.bold{
    font-weight: bold;
}

.align-left{
    text-align: left !important;
}
.align-center{
    text-align: center !important;
}
.align-right{
    text-align: right !important;
}


/* Loader code start
 <div id="pageLoader"><div class="loader"></div></div>
**/
#pageLoader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
   

  .loader-xs {
    border: 4px solid #ccc;
    border-top: 4px solid #1159a1;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }

  .loader-sm {
    border: 6px solid #ccc;
    border-top: 6px solid #1159a1;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  .loader-md {
    border: 8px solid #ccc;
    border-top: 8px solid  #1159a1;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }

  .loader-lg {
    border: 8px solid #ccc;
    border-top: 8px solid  #1159a1;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }

  .loader-xlg {
    border: 8px solid #ccc;
    border-top: 8px solid  #1159a1;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 1s linear infinite;
  }

  
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
/* Loader code end**/

@media only screen and (max-width: 767px){
    .hide-on-mobile{
        display: none;
    }
    .dashboard_outer{
        padding: 10px;
    }
    .dashboard_left {
        flex-basis: 210px;
    }
}