/* Form */
form {
    display: flex;
    flex-direction: column;
  }
  
/* form{
  display: flex;
  flex-direction: column-reverse;
} */

  input[type="text"] {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    margin-top: 10px;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  .error {
    color: red;
    margin-top: 10px;
  }
 
  .ppt_1 .rightSide_menu{
    overflow-y: inherit !important;
  }
  .ppt_1 #edit_add_ons{
    /* overflow-y: inherit !important;
    height: 100vh; */
    display: flex;
    flex-direction: column-reverse;
  }

  .ppt_1 #edit_add_ons .pl20.pr20{
    overflow-y: inherit !important;
  }